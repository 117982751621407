import React, { useState } from 'react';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import style from './style.css';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import evalQuery from './deploymentQuery.graphql';
import Loader from 'cccisd-loader';
import Confirm from 'cccisd-confirm';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const StartSurvey = props => {
    const surveyList =
        props.progress.role === 'coach'
            ? ['survey12', 'survey32', 'survey34', 'survey24']
            : ['survey36', 'survey37', 'survey38', 'survey39'];

    const modal = React.createRef();

    const [loading, setLoading] = useState(true);
    const [currentPawn, setCurrentPawn] = useState(props.pawn);
    const [assignmentId, setAssignmentId] = useState(null);
    const [deploymentId, setDeploymentId] = useState(null);

    const evaluateDeployment = async () => {
        const deploymentHandle =
            props.progress.role === 'coach' ? 'feedbackSurveyCoach' : 'feedbackSurveyTeacher';

        const response = await apollo.query({
            query: evalQuery,
            fetchPolicy: 'network-only',
            variables: {
                deploymentHandle,
            },
        });

        await repeatedDeployment(
            response.data.flows.deployment,
            response.data.flows.deployment.assignment.assignmentId
        );
    };

    const repeatedDeployment = async (deployment, id) => {
        const { pawn, progress } = props;

        let response = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: deployment.deploymentId,
                pawnId: +pawn.pawnId,
                hash: pawn.respondentHash,
            }),
            {
                params: props.progress.isExpired
                    ? {
                          forceRepeat: true,
                      }
                    : {},
            }
        );
        const responseSet =
            response.data.data.deploymentId < progress.deploymentId
                ? progress.deploymentId
                : response.data.data.deploymentId;

        setLoading(false);
        setCurrentPawn(pawn);
        setAssignmentId(id);
        setDeploymentId(responseSet);
    };

    const confirmStart = () => {
        if (!props.progress.surveyInProgress) {
            Confirm({
                message: `Warning`,
                description: `You are about to start a new feedback survey. Once this survey is started, it will be available to complete and edit for 30 days. During this time, you will not be able to start a new survey and no one else will be able to start a survey for you. After 30 days, this survey will no longer be editable, and a new survey may be started.`,
                confirmLabel: 'Continue',
                abortLabel: 'Cancel',
            }).then(() => {
                modal.current.open();
            });
        } else {
            modal.current.open();
        }
    };

    return (
        <div className={style.modalBox}>
            <button
                type="button"
                className={`btn btn-default ${style.buttonStyle}`}
                disabled={props.disabled}
                onClick={confirmStart}
            >
                {props.progress.surveyInProgress && !props.disabled && !props.progress.isExpired
                    ? `Resume Feedback Survey`
                    : `Start Feedback Survey`}
            </button>
            <Modal
                ref={modal}
                beforeShow={evaluateDeployment}
                afterClose={props.onNewClose}
                size="large"
                title="Feedback Survey"
            >
                <Loader loading={loading} removeChildren>
                    <div className={style.fixContainer}>
                        <DeploymentPlayer
                            deploymentId={deploymentId}
                            pawnId={+currentPawn.pawnId}
                            pawnHash={currentPawn.pawnHash}
                            onComplete={() => {
                                props.onComplete(
                                    currentPawn.pawnId,
                                    currentPawn.pawnHash,
                                    deploymentId,
                                    assignmentId
                                );
                            }}
                            assignmentOptions={surveyList}
                            flowProps={{ hidePlayButton: true }}
                        />
                    </div>
                </Loader>
            </Modal>
        </div>
    );
};

StartSurvey.propTypes = {
    onComplete: PropTypes.func,
    pawn: PropTypes.object,
    progress: PropTypes.object,
    onNewClose: PropTypes.func,
    disabled: PropTypes.bool,
};

export default StartSurvey;
